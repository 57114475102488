import { client } from './axios';

const registerOrLoginSocial = (body: AccountRegisterOrLoginSocialType): Promise<any> =>
  client.post(`/account-apis/api/profile/register-to-game`, body);

const login = (body: AccountLinkingType): Promise<UserType> =>
  client.post(`/account-apis/api/profile/check-password`, body);

const getProfile = (): Promise<UserType> => client.get(`/account-apis/api/profile`);

const linkAccount = (body: AccountLinkingType): Promise<any> =>
  client.post(`/account-apis/api/profile/link-to-account`, body);

const verifyAccount = (body: AccountVerifyType): Promise<any> =>
  client.post(`/account-apis/api/profile/verify-email`, body);

const resendCode = (body: { email: string }): Promise<any> =>
  client.post(`/account-apis/api/profile/resend-code`, body);

const subscribe = (body: { email: string }): Promise<any> => client.post(`/account-apis/api/subscribe`, body);

const getCodeByEmail = (body: AccountLinkingType): Promise<AccountRefCodeInfo> =>
  client.post(`/account-apis/api/battleroyale/receive-refcode`, body);

const checksSocialAccount = (body: AccountSocialCheckType): Promise<any> =>
  client.post(`/account-apis/api/battleroyale/check-social-account`, body);

export default {
  login,
  registerOrLoginSocial,
  getProfile,
  linkAccount,
  verifyAccount,
  resendCode,
  subscribe,
  getCodeByEmail,
  checksSocialAccount,
};
