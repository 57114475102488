import { AppBar, Button, Container, Toolbar, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { isEmail } from 'utils/validate';
import { useState } from 'react';
import { authService } from 'services';

const items = [
  {
    url: 'https://discord.gg/kcuUwerAGc',
    icon: require('assets/icons/socials/Discord.png'),
  },
  {
    url: 'https://twitter.com/MyMetaFarm',
    icon: require('assets/icons/socials/Twitter.png'),
  },
  {
    url: 'https://www.tiktok.com/@mymetafarm',
    icon: require('assets/icons/socials/Tiktok.png'),
  },
  {
    url: 'https://instagram.com/MyMetaFarmOfficial',
    icon: require('assets/icons/socials/Instagram.png'),
  },
  {
    url: 'https://www.youtube.com/channel/UCQ_NUT5gSaYfEDq61YbhDlA',
    icon: require('assets/icons/socials/Youtube.png'),
  },
];

const Footer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');

  const handleClickSubscribe = () => {
    if (isEmail(email.trim())) {
      authService.subscribe({ email }).then(() => {
        enqueueSnackbar('You have been successfully subscribed');
      });
    }
    setEmail('');
  };

  return (
    <AppBar component='footer' position='static' elevation={0} style={{ background: 'transparent' }}>
      <Toolbar>
        <Container className='py-[80px]'>
          <div className='flex flex-col items-center'>
            <div className='mb-10 sm:w-[510px]'>
              <div className='text-center text-xl font-bold' style={{ color: 'rgba(255, 232, 159, 1)' }}>
                See latest update about
                <br />
                My Meta Farm's events and news
              </div>
              <div className='flex flex-col sm:flex-row items-center gap-3 mt-6'>
                <TextField
                  size='small'
                  fullWidth
                  InputProps={{ className: 'bg-white/20 text-white' }}
                  placeholder='Enter Your Email'
                  value={email}
                  onChange={(event) => setEmail(event.target.value.trim())}
                />
                <Button
                  className='text-[#722828] font-black py-2 px-4'
                  style={{ background: 'linear-gradient(180deg, #FFDE48 0%, #FFBD0A 100%)' }}
                  onClick={handleClickSubscribe}
                >
                  Subscribe
                </Button>
              </div>
            </div>

            <div className='flex flex-wrap justify-center gap-4 md:gap-10 '>
              {items.map((item, index) => (
                <a key={index} href={item.url} target='_blank' rel='noreferrer'>
                  <Button
                    className='hover:brightness-90 w-[72px] h-[72px]'
                    style={{ background: `url(${item.icon})` }}
                  />
                </a>
              ))}
            </div>
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
