import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

const DesignButton = styled(LoadingButton)({
  background: `url(${require('assets/components/Rectangle.png')}) no-repeat center / contain`,
  minWidth: 378,
  minHeight: 88,
  fontWeight: 900,
  fontSize: 32,
  color: '#722828',
  '&:hover': {
    filter: 'brightness(0.85)',
    transform: 'scale(1.05)',
    transitionProperty: 'all',
    transitionDuration: '0.4s',
  },
});

export default DesignButton;
