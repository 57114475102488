import { Box } from '@mui/material';
import { styled } from '@mui/system';

const DesignBox = styled(Box)({
  background: `url(${require('assets/components/FrameBox.png')}) no-repeat center / contain`,
  width: 780,
  height: 240,
  fontWeight: 700,
  fontSize: 32,
  fontFamily: 'Hornbill',
  color: '#FFE89F',
  padding: '72px 170px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: 2,
});

export default DesignBox;
