export const WINDOW_URL = 'https://tinyurl.com/mrymknsz';
export const MAC_URL = 'https://tinyurl.com/3er6mfkf';
export const IOS_URL = 'https://tinyurl.com/5f8p29xn';
export const ANDROID_URL = 'https://tinyurl.com/yc2ptfxn';

export const formatNumber = (number) => Number(number ?? 0).toLocaleString();

export const shorten = (address, head = 6, tail = 6) => {
  return address ? address.slice(0, head) + '...' + address.slice(address.length - tail) : '';
};

export const copy = (text) => {
  navigator.clipboard.writeText(text);
};

export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getUrlFromOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  const windowsPlatforms = /(win32|win64|windows|wince)/i;
  const iosPlatforms = /(iphone|ipad|ipod)/i;

  if (macosPlatforms.test(userAgent)) return MAC_URL;
  else if (iosPlatforms.test(userAgent)) return IOS_URL;
  else if (windowsPlatforms.test(userAgent)) return WINDOW_URL;
  else if (/android/.test(userAgent)) return ANDROID_URL;
  else return '';
};

export { default as merge } from './merge';
