import { Dialog } from '@mui/material';
import { useWindowDimensions } from 'hooks';

type DialogProps = {
  children?: React.ReactNode;
  open: boolean;
  maxWidthType?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const DesignDialog = ({ children, open, maxWidthType }: DialogProps) => {
  const { isLgScreen } = useWindowDimensions();

  const type = maxWidthType ?? (isLgScreen ? 'xs' : 'sm');
  return (
    <Dialog
      fullWidth
      maxWidth={type}
      open={open}
      PaperProps={{
        style: {
          background: `transparent url(${require('../assets/components/FramePopup.png')}) no-repeat center`,
          boxShadow: 'none',
          backgroundSize: 'calc(max(100%, 100px)) calc(max(100%, 100px))',
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export default DesignDialog;
