import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { merge } from 'utils/common';
import styles from './SocialButton.module.scss';

const SocialButton = (props: LoadingButtonProps) => {
  const { fullWidth } = props;
  return (
    <div className={merge('relative', { 'w-full': fullWidth })}>
      <LoadingButton
        variant='contained'
        size='large'
        classes={{
          contained: styles.contained,
          containedInfo: styles.containedInfo,
          containedPrimary: styles.containedPrimary,
          containedSecondary: styles.containedSecondary,
          containedInherit: styles.containedInherit,
          disabled: styles.disabled,
        }}
        {...props}
      />
      <div></div>
    </div>
  );
};

export default SocialButton;
