import { Button, Container } from '@mui/material';
import { DesignButton, DesignDialog, InfiniteSlider } from 'components';
import { useRef, useState } from 'react';
import { ANDROID_URL, getUrlFromOS, IOS_URL, merge } from 'utils/common';
import { RegisterPopup } from 'views/Home/popups';

export const ButtonGoogle = ({ isRegister = false }: { isRegister?: boolean }) => (
  <a href={ANDROID_URL} target='_blank'>
    {isRegister ? (
      <Button
        style={{ background: `url(${require('../assets/icons/GooglePlay.png')}) no-repeat center` }}
        className='h-[56px] w-[186px] hover:brightness-125'
      />
    ) : (
      <Button className='hover:brightness-125 p-2'>
        <img src={require('../assets/icons/GooglePlay.png')} className='h-6 md:h-[56px] md:w-[186px]' />
      </Button>
    )}
  </a>
);

export const ButtonApple = ({ isRegister = false }: { isRegister?: boolean }) => (
  <a href={IOS_URL} target='_blank'>
    {isRegister ? (
      <Button
        style={{ background: `url(${require('../assets/icons/AppStore.png')}) no-repeat center` }}
        className='h-[56px] w-[189px] hover:brightness-125'
      />
    ) : (
      <Button className='hover:brightness-125 p-2'>
        <img src={require('../assets/icons/AppStore.png')} className='h-6 md:h-[56px] md:w-[189px]' />
      </Button>
    )}
  </a>
);

export const ButtonPC = ({ isRegister = false }: { isRegister?: boolean }) => {
  const url = getUrlFromOS();

  if (!url) return null;

  return (
    <a href={url} target='_blank'>
      {isRegister ? (
        <Button
          style={{ background: `url(${require('../assets/icons/PC.png')}) no-repeat center` }}
          className='h-[56px] w-[189px] hover:brightness-125'
        />
      ) : (
        <Button className='hover:brightness-125 p-2'>
          <img src={require('../assets/icons/PC.png')} className='h-6 md:h-[56px] md:w-[189px]' />
        </Button>
      )}
    </a>
  );
};

const Banner = () => {
  const slider = useRef<InfiniteSlider>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <div className='relative'>
      <div
        data-aos='fade-up-right'
        data-aos-duration='2000'
        className='absolute z-50 right-20 top-60 w-[160px] hidden xl:block'
      >
        <img src={require('../assets/icons/BUTTER2.png')} className='go-jump' />
      </div>
      <div
        data-aos='fade-up-right'
        data-aos-duration='2000'
        className='absolute z-20 left-4 bottom-40 w-[160px] hidden xl:block'
      >
        <img src={require('../assets/icons/BUTTER1.png')} className='go-jump' />
      </div>

      <InfiniteSlider
        ref={slider}
        {...{ autoplay: true, dots: false, arrows: false, speed: 1500 }}
        beforeChange={(current, next) => {
          setActiveIndex(next);
          setIsShow(true);
        }}
        afterChange={() => setIsShow(false)}
      >
        {[1, 2, 3].map((item, index) => (
          <video
            key={index}
            {...{ autoPlay: true, muted: true, loop: true }}
            src={require(`../assets/videos/BannerSlide${item}.mp4`)}
          />
        ))}
      </InfiniteSlider>

      <img src={require('../assets/images/BannerSmoke.png')} className='absolute top-0 left-0 min-w-[200vw] go-left' />
      <div className='absolute inset-0 flex items-center'>
        <Container
          className={merge('flex justify-start 2xl:mt-28', {
            '2xl:ml-40': activeIndex === 0,
            '2xl:ml-24': activeIndex >= 1,
            hidden: isShow,
          })}
        >
          <div className='flex flex-col items-center z-10 absolute md:relative top-2 sm:top-[20%] left-2 sm:left-4'>
            <img
              src={require(`../assets/images/BannerLogo${activeIndex + 1}.png`)}
              className={merge('h-[92px] sm:h-[140px] md:h-[200px]', {
                'xl:h-[300px]': activeIndex === 0,
                'w-[250px] sm:w-[360px] md:w-[540px] xl:w-auto xl:h-[160px]': activeIndex === 2,
              })}
            />
            <DesignButton
              className='be-pulse min-w-[145px] min-h-[56px] lg:min-h-[60px] lg:min-w-[250px] xl:min-h-[70px] xl:min-w-[348px] text-xs lg:text-lg'
              onClick={() => setOpenRegister(true)}
            >
              REGISTER NOW
            </DesignButton>
            <div className='flex lg:gap-x-8 lg:mt-8 xl:mb-6'>
              <ButtonGoogle />
              <ButtonApple />
              <ButtonPC />
            </div>
            <img src={require('../assets/icons/ArrowDown.png')} className='go-scroll hidden md:block' />
          </div>
        </Container>
      </div>

      <DesignDialog open={openRegister}>
        <RegisterPopup onClose={() => setOpenRegister(false)} />
      </DesignDialog>
    </div>
  );
};

export default Banner;
