import { useQuery } from '@tanstack/react-query';
import { useNotification } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signIn, signOut } from 'reducers/profileSlice';
import { saveSystem } from 'reducers/systemSlice';
import { systemService } from 'services';

const Controller = ({ children }: any) => {
  useNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile')!);
      if (profile) {
        dispatch(signIn(profile));
      }
    } catch {
      dispatch(signOut());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useQuery(['fetchPayments'], () => systemService.fetchPayments(), { staleTime: Infinity });

  const { isSuccess } = useQuery(['fetchConfig'], () => systemService.fetchConfig(), {
    onSuccess: (data) => {
      dispatch(saveSystem(data));
    },
    staleTime: Infinity,
  });

  return isSuccess ? children : <></>;
};

export default Controller;
