import { CloseOutlined, Logout, MenuOutlined } from '@mui/icons-material';
import { AppBar, Button, Container, IconButton, Toolbar } from '@mui/material';
import { DesignButton, DesignDialog } from 'components';
import { AppMenu } from 'containers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profileSlice';
import { publicRoute } from 'routes';
import { ReferralPopup, RegisterPopup } from 'views/Home/popups';

const Header = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openReferralPopup, setOpenReferralPopup] = useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn, email } = useSelector(profileSelector);

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return (
    <>
      <AppBar component='header' elevation={0} style={{ background: '#22065db3' }}>
        <Toolbar className='h-[100px]'>
          <Container maxWidth='xl' className='flex items-center px-0'>
            <Link to={publicRoute.home.path}>
              <img src={require('assets/icons/MyMetaFarm.png')} className='h-[52px] sm:h-[88px]' />
            </Link>

            <div className='flex-1 xl:flex-none'></div>
            <div className='mobile-nav' style={{ display: showMenu ? 'flex' : 'none' }}>
              <AppMenu />
            </div>

            <div className='xl:w-[290px] 2xl:w-auto flex justify-center'>
              {isLoggedIn ? (
                <div className='flex'>
                  <Button variant='outlined' onClick={() => setOpenReferralPopup(true)}>
                    {email}
                  </Button>
                  <IconButton className='ml-3 text-white hidden sm:block' onClick={() => dispatch(signOut())}>
                    <Logout />
                  </IconButton>
                </div>
              ) : (
                <DesignButton
                  className='min-w-[145px] text-lg xl:text-[32px] xl:min-w-[378px] xl:scale-[0.6]'
                  onClick={() => setOpenRegister(true)}
                >
                  Register
                </DesignButton>
              )}
            </div>

            <IconButton color='secondary' className='hamburger-icon ml-3' onClick={() => setShowMenu((prev) => !prev)}>
              {showMenu ? <CloseOutlined /> : <MenuOutlined />}
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>

      <DesignDialog open={openRegister}>
        <RegisterPopup onClose={() => setOpenRegister(false)} />
      </DesignDialog>

      <DesignDialog open={openReferralPopup}>
        <ReferralPopup onClose={() => setOpenReferralPopup(false)} />
      </DesignDialog>
    </>
  );
};

export default Header;
