import { Container, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody } from '@mui/material';
import { DesignBox, DesignButton, DesignDialog } from 'components';
import { useState } from 'react';
import { RegisterPopup, RulesPopup } from 'views/Home/popups';

const rewards = ['600', '500', '400', '250', '180', '160', '140', '120', '90', '60'];

const Referral = () => {
  const [openRules, setOpenRules] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <Container id='REFERRAL' className='relative flex flex-col items-center py-10 md:py-20'>
      <div
        data-aos='fade-up-right'
        data-aos-duration='2000'
        className='absolute right-0 md:right-80 top-[-80px] w-[160px]'
      >
        <img src={require('../assets/icons/BUTTER1.png')} className='go-jump' />
      </div>
      <div
        data-aos='fade-up-right'
        data-aos-duration='2000'
        className='absolute left-0 hidden md:block md:bottom-[-320px] w-[160px]'
      >
        <img src={require('../assets/icons/BUTTER2.png')} className='go-jump' />
      </div>

      <img src={require('../assets/icons/DECOR4.png')} className='absolute go-jump left-[-180px] top-80' />
      <img src={require('../assets/icons/DECOR2.png')} className='absolute go-jump right-[-240px] top-[420px]' />
      <img src={require('../assets/icons/DECOR3.png')} className='absolute go-jump right-[-20px] bottom-[-120px]' />

      <DesignBox className='p-0 text-base h-[80px] md:h-[240px] md:text-[32px] md:leading-[48px]'>
        REFERRAL PROGRAM
      </DesignBox>
      <div className='font-medium text-xl md:text-2xl my-6'>
        Join Battle Royale with your friends now to receive reward.{' '}
        <span className='underline text-primary-main cursor-pointer' onClick={() => setOpenRules(true)}>
          See Rules
        </span>
      </div>
      <div className='bg-gradient font-black text-2xl md:text-5xl md:px-28 py-6 my-3'>20,000 $MMF Token</div>

      <div className='relative mt-[640px] w-full flex justify-center'>
        <img
          src={require('../assets/images/ReferralSpin.png')}
          className='absolute bottom-0 md:bottom-[-144px] animateSpin'
        />
        <img src={require('../assets/images/ReferralHero.png')} className='absolute bottom-[-30px] mr-[10px]' />

        <img
          src={require('../assets/images/ReferralCard1.png')}
          className='absolute left-0 lg:left-[320px] top-[-560px] animateCard1'
        />
        <img
          src={require('../assets/images/ReferralCard2.png')}
          className='absolute right-0 lg:right-[320px] top-[-560px] animateCard2'
        />
        <img
          src={require('../assets/images/ReferralCard3.png')}
          className='absolute hidden sm:block left-0 lg:left-[120px] top-[-100px] animateCard2'
        />
        <img
          src={require('../assets/images/ReferralCard4.png')}
          className='absolute hidden sm:block right-0 lg:right-[160px] top-[-100px] animateCard1'
        />
        <DesignButton onClick={() => setOpenRegister(true)} className='scale-[0.7] md:scale-[1]'>
          INVITE NOW
        </DesignButton>
      </div>

      <DesignDialog maxWidthType='md' open={openRules}>
        <RulesPopup onClose={() => setOpenRules(false)}>
          <div className='text-sm'>
            <p>
              After successfully registering My Meta Farm account, each account will have personal referral links to
              invite friends. Sending referral link to your friends to play the game together and get rewards.
            </p>
            <p className='my-8'>
              Invitations will be valid when your friends register for an account and log in to the game. The total
              prize pool is 4,500 $MMF Tokens for the Top 10 and the 20 Luckiest participants who invite friends.
            </p>
            <div className='flex flex-col items-center gap-6 justify-center'>
              <TableContainer component={Paper} className='max-w-[267px] rounded-none bg-transparent'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} className='bg-[#B89628] font-extrabold' align='center'>
                        Top 10 <br />
                        (Total Prize: 2,500 $MMF)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Top</TableCell>
                      <TableCell align='center'>$MMF Token rewards</TableCell>
                    </TableRow>
                    {rewards.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align='center'>{item}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className='max-w-[267px] rounded-none bg-transparent'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} className='bg-[#B89628] font-extrabold' align='center'>
                        20 LUCKIEST PARTICIPANTS <br /> (Total Prize: 2,000 $MMF)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Random</TableCell>
                      <TableCell align='center'>MMF/ each participant</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>20</TableCell>
                      <TableCell align='center'>100</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className='flex justify-start mt-6 font-normal'>
              <div className='mr-4'>NOTE</div>
              <div className='italic'>
                - The more friends you invite, the greater your chances of winning. <br />- To become an eligible
                participant, you must invite at least 1 friend.
              </div>
            </div>
          </div>
        </RulesPopup>
      </DesignDialog>

      <DesignDialog open={openRegister}>
        <RegisterPopup onClose={() => setOpenRegister(false)} />
      </DesignDialog>
    </Container>
  );
};

export default Referral;
