import { FormControl, TextField } from '@mui/material';
import { DesignButton, InputPassword } from 'components';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { authService } from 'services';
import { TabVerify } from '.';

type TabAccountActiveProps = {
  onClose: () => void;
  onSwitch: () => void;
};

const TabAccountActive = ({ onClose, onSwitch }: TabAccountActiveProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { ...profile } = useSelector(profileSelector);

  const { control, handleSubmit, watch } = useForm();
  const { email } = watch();

  const [isVerify, setIsVerify] = useState(false);

  const { mutate, isLoading } = useMutation(authService.login, {
    onSuccess: (data) => {
      const { emailConfirmed } = data;
      if (emailConfirmed) {
        dispatch(signIn({ ...data, isLoggedIn: true }));
        onClose();
      } else {
        setIsVerify(true);
        authService.resendCode({ email }).then(() => {
          enqueueSnackbar('Activation code has been sent');
        });
      }
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate(values as AccountLinkingType);
    })();
  };

  return isVerify ? (
    <TabVerify email={email} onBack={() => setIsVerify(false)} />
  ) : (
    <div className='flex flex-col gap-[24px]'>
      <Controller
        name='email'
        defaultValue={profile.email ?? ''}
        control={control}
        rules={{ required: 'Email is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Email</label>
            <TextField
              {...field}
              type='email'
              error={invalid}
              helperText={error?.message}
              InputProps={{ readOnly: !!profile.email }}
            />
          </FormControl>
        )}
      />

      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{ required: 'Password is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Password</label>
            <InputPassword {...field} error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />

      <div className='flex justify-center'>
        <DesignButton className='scale-[0.6]' loading={isLoading} onClick={handleClickSubmit}>
          NEXT
        </DesignButton>
      </div>

      <div className='flex justify-center text-[14px] text-white'>
        Do not have account?
        <span onClick={onSwitch} className='underline cursor-pointer ml-2'>
          Create account
        </span>
      </div>
    </div>
  );
};

export default TabAccountActive;
