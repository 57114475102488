import { Facebook, Google } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { FacebookLogin, GoogleLogin, SocialButton } from 'components';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from 'env';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { useMutation } from '@tanstack/react-query';
import { authService } from 'services';
import { useDispatch } from 'react-redux';
import { signIn } from 'reducers/profileSlice';

type LoginSocialProps = {
  title: string;
  onTypeChange?: (type: number) => void;
  onDataChange?: (data: GoogleLoginResponse | ReactFacebookLoginInfo) => void;
  onClose?: () => void;
  onShowReferralPopup?: () => void;
  isRegister?: boolean;
};

const LoginSocial = ({ isRegister, title, onTypeChange, onDataChange, onShowReferralPopup }: LoginSocialProps) => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(authService.getCodeByEmail, {
    onSuccess: (data) => {
      dispatch(signIn({ ...data, isLoggedIn: true }));
      onShowReferralPopup?.();
    },
  });

  return (
    <>
      <Divider className='text-sm font-bold'>{title}</Divider>
      <div className='flex items-center justify-center gap-4 mb-2'>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID!}
          onSuccess={(response) => {
            const data = response as GoogleLoginResponse;
            if (!isRegister) mutate({ token: data.tokenId, linkType: 30 });
            else
              authService.checksSocialAccount({ linkType: 30, token: data.tokenId }).then((res) => {
                if (res) {
                  mutate({ token: data.tokenId, linkType: 30 });
                } else {
                  onTypeChange?.(30);
                  onDataChange?.(data);
                }
              });
          }}
          onFailure={() => onTypeChange?.(10)}
          render={({ onClick, disabled }) => (
            <SocialButton
              color='secondary'
              startIcon={<Google />}
              disabled={disabled}
              loading={false}
              onClick={onClick}
              className='w-36'
            >
              GOOGLE
            </SocialButton>
          )}
        />

        <FacebookLogin
          appId={FACEBOOK_APP_ID!}
          callback={(response) => {
            const data = response as ReactFacebookLoginInfo;
            if (!isRegister) mutate({ token: data.accessToken, linkType: 20 });
            else
              authService.checksSocialAccount({ linkType: 20, token: data.accessToken }).then((res) => {
                if (res) {
                  mutate({ token: data.accessToken, linkType: 20 });
                } else {
                  onTypeChange?.(20);
                  onDataChange?.(data);
                }
              });
          }}
          onFailure={() => onTypeChange?.(10)}
          render={({ onClick, isSdkLoaded, isDisabled, isProcessing }) => (
            <SocialButton
              color='info'
              startIcon={<Facebook />}
              disabled={isDisabled || !isSdkLoaded}
              loading={isProcessing}
              onClick={onClick}
              className='w-36'
            >
              FACEBOOK
            </SocialButton>
          )}
        />
      </div>
    </>
  );
};

export default LoginSocial;
