import { Check } from '@mui/icons-material';
import { DesignButton } from 'components';
import { useContext } from 'react';
import { RegisterContext } from 'views/Home/popups';

const TabVerifySuccess = () => {
  const context = useContext(RegisterContext);

  return (
    <div className='flex flex-col items-center gap-[24px]'>
      <div className='flex justify-center items-center bg-green-400 rounded-full w-[32px] h-[32px]'>
        <Check className='text-white' />
      </div>

      <div className='text-center text-white'>You have successfully create game account.</div>

      <div className='flex justify-center'>
        <DesignButton
          className='scale-[0.6]'
          onClick={(event) => {
            context?.onTabChange(event, 'reference');
          }}
        >
          Get Code
        </DesignButton>
      </div>
    </div>
  );
};

export default TabVerifySuccess;
