import { Close, InfoOutlined } from '@mui/icons-material';
import { Avatar, Chip, FormControl, InputAdornment, TextField, Tooltip } from '@mui/material';
import { DesignButton } from 'components';
import { useSnackbar } from 'notistack';
import { parse } from 'qs';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { authService } from 'services';
import { isUsername } from 'utils/validate';

type TabSocialProps = {
  registerType: number;
  registerData: GoogleLoginResponse | ReactFacebookLoginInfo;
  onBack: () => void;
  onClose: () => void;
};

const TabSocial = ({ registerType, registerData, onClose, onBack }: TabSocialProps) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { refCode } = parse(location.search, { ignoreQueryPrefix: true });

  const { control, handleSubmit } = useForm();

  // 30: Google / 20: Facebook
  const isGoogle = registerType === 30;
  const google = registerData as GoogleLoginResponse;
  const facebook = registerData as ReactFacebookLoginInfo;

  const { mutate, isLoading } = useMutation(authService.registerOrLoginSocial, {
    onSuccess: () => {
      enqueueSnackbar('Your account has been successfully created');
      onClose();
    },
  });

  const handleRegisterSocial = () => {
    handleSubmit(({ refCode, username }) => {
      mutate({
        username,
        token: isGoogle ? google.tokenId : facebook.accessToken,
        refCode,
        registerType,
      } as AccountRegisterOrLoginSocialType);
    })();
  };

  return (
    <div className='flex flex-col gap-[24px]'>
      <div className='flex justify-center'>
        {isGoogle ? (
          <Chip
            className='bg-white'
            avatar={<Avatar alt={google.profileObj.name} src={google.profileObj.imageUrl} />}
            label={google.profileObj.name}
            deleteIcon={<Close />}
            onDelete={onBack}
          />
        ) : (
          <Chip
            className='bg-white'
            avatar={<Avatar alt={facebook.name} src={facebook.picture?.data.url} />}
            label={facebook.name}
            deleteIcon={<Close />}
            onDelete={onBack}
          />
        )}
      </div>

      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{
          required: 'Username is required',
          minLength: { value: 6, message: 'Username is 6 characters minimum' },
          validate: {
            pattern: (value) => isUsername(value) || 'Username is not valid',
          },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Username</label>
            <TextField
              {...field}
              error={invalid}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={`Only letters (a-z) and numbers (0-9) are accepted`}>
                      <InfoOutlined className='cursor-pointer text-black/50' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
      />

      <Controller
        name='refCode'
        defaultValue={refCode ?? ''}
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Referral Code (Optional)</label>
            <TextField {...field} type='text' error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />

      <div className='flex justify-center'>
        <DesignButton className='scale-[0.6]' onClick={handleRegisterSocial} loading={isLoading}>
          NEXT
        </DesignButton>
      </div>
    </div>
  );
};

export default TabSocial;
