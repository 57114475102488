import { FormControl, TextField } from '@mui/material';
import { DesignButton } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { authService } from 'services';
import { LoginSocial } from '.';
import { useState } from 'react';
import { GoogleLoginResponse } from 'react-google-login';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { ReferralPopup } from 'views/Home/popups';
import { useDispatch } from 'react-redux';
import { signIn } from 'reducers/profileSlice';
import { isEmail } from 'utils/validate';

type TabGetRefCodeProp = {
  onClose: () => void;
};

const TabGetRefCode = ({ onClose }: TabGetRefCodeProp) => {
  const { control, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();

  const [openReferralPopup, setOpenReferralPopup] = useState(false);

  const [registerType, setRegisterType] = useState<number>(10);
  const [registerData, setRegisterData] = useState<GoogleLoginResponse | ReactFacebookLoginInfo>();

  const isGoogle = registerType === 30;
  const google = registerData as GoogleLoginResponse;
  const facebook = registerData as ReactFacebookLoginInfo;

  const { mutate, isLoading } = useMutation(authService.getCodeByEmail, {
    onSuccess: (data) => {
      dispatch(signIn({ ...data, isLoggedIn: false }));
      setOpenReferralPopup(true);
    },
    onError: () => {
      setValue('email', '');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      const { email } = values;
      mutate(
        !email
          ? ({
              linkType: registerType,
              token: isGoogle ? google.tokenId : facebook.accessToken,
            } as AccountLinkingType)
          : { email },
      );
    })();
  };

  return openReferralPopup ? (
    <ReferralPopup onClose={() => setOpenReferralPopup(false)} />
  ) : (
    <div className='flex flex-col gap-2'>
      <div className='text-sm text-white mb-2'>Please enter your email or register to get your referral code!</div>

      <Controller
        name='email'
        defaultValue=''
        control={control}
        rules={{
          required: 'Email is required',
          validate: {
            pattern: (value) => isEmail(value) || 'Email is not valid',
          },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Email</label>
            <TextField {...field} type='email' error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />

      <div className='flex justify-center'>
        <DesignButton className='scale-[0.6]' loading={isLoading} onClick={handleClickSubmit}>
          Confirm
        </DesignButton>
      </div>

      <LoginSocial
        title='or Log In with'
        onClose={onClose}
        onShowReferralPopup={() => setOpenReferralPopup(true)}
        onTypeChange={setRegisterType}
        onDataChange={setRegisterData}
      />
    </div>
  );
};

export default TabGetRefCode;
