import { IconButton, IconButtonProps } from '@mui/material';

const CloseButton = (props: IconButtonProps) => {
  return (
    <IconButton className='absolute top-4 right-4 md:right-8' color='info' {...props}>
      <img src={require(`../assets/components/Close.svg`).default} />
    </IconButton>
  );
};

export default CloseButton;
