import { Button, Container } from '@mui/material';
import { DesignBox, InfiniteSlider } from 'components';
import { useRef } from 'react';

const nav = [
  {
    image: require('../assets/images/FeatureDigital.webp'),
  },
  {
    image: require('../assets/images/FeatureExtreme.webp'),
  },
  {
    image: require('../assets/images/FeatureFreePlay.webp'),
  },
  {
    image: require('../assets/images/FeatureLastSurvivor.png'),
  },
  {
    image: require('../assets/images/FeatureWinMMF.png'),
  },
  {
    image: require('../assets/images/FeatureBestFreeOnline.png'),
  },
  {
    image: require('../assets/images/FeatureFreeToPlay.png'),
  },
];

const Feature = () => {
  const slider = useRef<InfiniteSlider>(null);

  return (
    <Container maxWidth='lg' className='relative py-20' id='EXCLUSIVE'>
      <img src={require('../assets/icons/DECOR5.png')} className='absolute go-jump left-[-240px] bottom-[-120px]' />
      <img src={require('../assets/icons/DECOR3.png')} className='absolute go-jump right-[-180px] bottom-0' />

      <div className='flex justify-center mb-20'>
        <DesignBox className='p-0 text-base h-[80px] md:h-[240px] md:text-[32px] md:leading-[48px]'>
          EXCLUSIVE FEATURES
        </DesignBox>
      </div>
      <Button
        className='hidden xl:block absolute right-[-40px] bottom-[30%] hover:brightness-80 w-[60px] h-[60px] rounded-full'
        style={{ background: `url(${require('../assets/icons/ButtonNext.png')})` }}
        onClick={() => slider.current?.slickNext()}
      />
      <Button
        className='hidden xl:block absolute left-[-40px] bottom-[30%] hover:brightness-80 w-[60px] h-[60px] rounded-full'
        style={{ background: `url(${require('../assets/icons/ButtonPrev.png')})` }}
        onClick={() => slider.current?.slickPrev()}
      />

      <InfiniteSlider ref={slider} {...{ autoplay: true, dots: false, arrows: false }}>
        {nav.map((item, index) => (
          <Container maxWidth='md' key={index} className='flex justify-center'>
            <img src={item.image} />
          </Container>
        ))}
      </InfiniteSlider>
    </Container>
  );
};

export default Feature;
