import { DialogContent, Tab, Tabs } from '@mui/material';
import { CloseButton } from 'components';
import { useTabs } from 'hooks';
import { TabType } from 'hooks/useTabs';
import { RegisterContext } from '.';
import { TabAccountCreate, TabAccountActive, TabGetRefCode } from 'views/Home/components';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { SyntheticEvent, useEffect, useState } from 'react';

type RegisterPopupProps = PopupController & {};

const RegisterPopup = ({ onClose }: RegisterPopupProps) => {
  const { email, emailVerified } = useSelector(profileSelector);

  const [isCreate, setIsCreate] = useState(true);

  const tabs = ([] as TabType[]).concat(
    [
      {
        code: 'register',
        label: isCreate ? 'REGISTER' : 'ACTIVE',
        component: isCreate ? (
          <TabAccountCreate onClose={onClose} onSwitch={() => setIsCreate((prev) => !prev)} />
        ) : (
          <TabAccountActive onClose={onClose} onSwitch={() => setIsCreate((prev) => !prev)} />
        ),
      },
    ].concat([
      {
        code: 'reference',
        label: 'GET CODE',
        component: <TabGetRefCode onClose={onClose} />,
      },
    ]),
  );

  const [activeTab, onTabChange] = useTabs(tabs);

  useEffect(() => {
    if (email && !emailVerified) {
      onTabChange({} as SyntheticEvent<Element, Event>, 'register');
      setIsCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegisterContext.Provider value={{ onTabChange, onClose }}>
      <>
        <DialogContent>
          <Tabs
            value={activeTab}
            onChange={onTabChange}
            textColor='inherit'
            classes={{ flexContainer: 'justify-center' }}
            sx={{ marginTop: 4 }}
            TabIndicatorProps={{ style: { background: 'transparent' } }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.code}
                className='w-[154px] text-2xl text-white font-bold'
                style={{ wordBreak: 'break-word' }}
                label={tab.label}
                value={tab.code}
              />
            ))}
          </Tabs>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-[24px] px-8'>
              {tab.component}
            </div>
          ))}
        </DialogContent>
        <CloseButton onClick={onClose} />
      </>
    </RegisterContext.Provider>
  );
};

export default RegisterPopup;
