import { Container, List, ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { DesignBox, InfiniteSlider } from 'components';
import { useRef, useState } from 'react';

const StyledListItem = styled(ListItemButton)({
  fontWeight: 900,
  fontSize: 24,
  padding: '20px 40px',
  marginBottom: 8,
  '&:hover, &.Mui-selected': {
    background: 'linear-gradient(89.59deg, #4E6AFF 1.96%, #BE6BFF 52.84%, #FFC5A4 98.68%)',
  },
  '@media (max-width: 640px)': {
    fontSize: 16,
  },
  borderRadius: 16,
});

const nav = [
  {
    label: 'DAILY CHECK-IN',
    image: require('../assets/videos/CheckIn.gif'),
    content: `The Daily Check-in event in Battle Royale is a system that rewards players for attending the game on a daily basis. The more attendance checks you complete, the more rewards you get. There are special milestones on days 7, 14, and 21 that give valuable rewards.`,
    url: 'https://news.mymetafarm.com/my-meta-farm-alpha-test-daily-check-in/',
  },
  {
    label: 'SLOT MACHINE',
    image: require('../assets/videos/SlotMachine.gif'),
    content: `This is a game of chance for gamers. Each player will have certain turns free every day and will push the button on the slot machine to have a chance to win points. The luckier you are, the higher your chances of winning points. Points will be redeemed for the rewards.`,
    url: 'https://news.mymetafarm.com/my-meta-farm-alpha-test-slot-machine/',
  },
  {
    label: 'FORTUNE WHEEL',
    image: require('../assets/videos/FortuneWheel.gif'),
    content: `Using the points that players have win from the slot machines or Battle Royale game to buy turns on the lucky wheel. Each spin will be a chance to win our valuable and attractive NFTs. The more points you get, the greater your chance to have spins and win rewards.`,
    url: 'https://news.mymetafarm.com/my-meta-farm-alpha-test-fortune-wheel/',
  },
  {
    label: 'TOP PVE',
    image: require('../assets/videos/TopPvE.gif'),
    content: `Combat the characters you meet and become the last survivor in Battle Royale. The top 5 players with the highest score will be given points to redeem. Let's fight to see if you can be in the top 5 survivors.`,
    url: 'https://news.mymetafarm.com/my-meta-farm-alpha-test-pve/',
  },
];

const Campaign = () => {
  const slider = useRef<InfiniteSlider>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className='relative py-20' id='GAME'>
      <img src={require('../assets/icons/DECOR6.png')} className='absolute go-jump left-20 bottom-[-240px]' />
      <img src={require('../assets/icons/DECOR7.png')} className='absolute go-jump right-40 bottom-[-240px]' />

      <div className='flex justify-center xl:mb-20'>
        <DesignBox className='p-0 text-base h-[80px] md:h-[240px] md:text-[32px] md:leading-[48px]'>
          CAMPAIGNS - IN GAME
        </DesignBox>
      </div>

      <div className='flex flex-wrap justify-center gap-4'>
        <List className='w-full md:w-[400px] md:mt-10 px-4'>
          {nav.map((item, index) => (
            <StyledListItem
              key={index}
              selected={index === activeIndex}
              onClick={() => {
                slider.current?.slickGoTo?.(index - 1);
              }}
            >
              {item.label}
            </StyledListItem>
          ))}
        </List>

        <div className='w-full md:w-[580px] md:min-h-[640px]'>
          <InfiniteSlider
            ref={slider}
            {...{ autoplay: true, dots: false, arrows: false }}
            beforeChange={(_, next) => setActiveIndex((next + 1) % nav.length)}
          >
            {nav.map((item, index) => (
              <Container maxWidth='md' key={index}>
                <img
                  src={nav[activeIndex].image}
                  style={{ border: '3px solid #FFEEC3', borderRadius: 12 }}
                  className='w-full'
                />
                <div className='font-medium text-xl mt-10 mb-6'>{nav[activeIndex].content}</div>
                <a
                  href={nav[activeIndex].url}
                  target='_blank'
                  rel='noreferrer noopener'
                  className='text-2xl text-[#8FDFFF] hover:brightness-90 inline-flex items-center gap-2'
                >
                  <span>Detail</span>
                  <img src={require(`../assets/icons/Share.svg`).default} />
                </a>
              </Container>
            ))}
          </InfiniteSlider>
        </div>

        <div className='p-4 md:p-0 hidden 2xl:block'>
          <img src={nav[(activeIndex + 1) % 4].image} style={{ border: '2px solid #FFEEC3', borderRadius: 12 }} />
        </div>
      </div>
    </div>
  );
};

export default Campaign;
