import { Button, Slide } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import { useState } from 'react';
import { merge } from 'utils/common';
import { ButtonApple, ButtonGoogle, ButtonPC } from './Banner';

const Register = () => {
  const { isMdScreen } = useWindowDimensions();
  const [openRegister, setOpenRegister] = useState(isMdScreen ? false : true);
  return (
    <div
      className={merge('fixed right-3 z-50 top-48', {
        'w-[261px] h-[440px]': openRegister,
      })}
    >
      <Button
        className={merge('absolute top-[50%]', {
          '-left-12': openRegister,
          'right-0': !openRegister,
        })}
        onClick={() => setOpenRegister(!openRegister)}
      >
        <img src={require(`../assets/icons/${openRegister ? 'ButtonClose' : 'ButtonOpen'}.png`)} />
      </Button>
      <Slide direction='left' in={openRegister} mountOnEnter unmountOnExit>
        <div style={{ background: `url(${require('../assets/images/RegisterFrame.png')}) no-repeat` }}>
          <div className='relative flex flex-col justify-center items-center p-8' style={{ minHeight: 440 }}>
            <img src={require('../assets/images/RegisterCode.png')} />
            <img
              src={require('../assets/icons/BUTTER2.png')}
              className='absolute top-[-120px] left-[-120px] w-[240px]'
            />

            <div className='flex flex-col gap-2 m-2 scale-[0.85]'>
              <ButtonPC isRegister={true} />
              <ButtonApple isRegister={true} />
              <ButtonGoogle isRegister={true} />
            </div>

            <div className='flex gap-2'>
              <a href='https://twitter.com/MyMetaFarm' target='_blank' rel='noreferrer'>
                <Button
                  className='hover:brightness-90 w-[60px] h-[60px]'
                  style={{
                    background: `url(${require('assets/icons/socials/Twitter.png')}) no-repeat center / contain`,
                  }}
                />
              </a>
              <a href='https://discord.gg/kcuUwerAGc' target='_blank' rel='noreferrer'>
                <Button
                  className='hover:brightness-90 w-[60px] h-[60px]'
                  style={{
                    background: `url(${require('assets/icons/socials/Discord.png')}) no-repeat center / contain`,
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default Register;
