import {
  Box,
  BoxProps,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DesignBox, DesignButton, DesignDialog } from 'components';
import { useState } from 'react';
import { marketService } from 'services';
import { numberWithCommas, merge } from 'utils/common';
import { RulesPopup } from 'views/Home/popups';

type StepType = BoxProps & {
  imageSrc: string;
  rewards: string[];
  isMileStone?: boolean;
};

const StepBox = ({ imageSrc, rewards, isMileStone = false, ...props }: StepType) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} {...props}>
    <img
      src={imageSrc}
      className={merge({
        'zoom-in-zoom-out': isMileStone,
      })}
    />
    <DesignButton
      className={merge('justify-center scale-[0.5] relative top-[-20px] flex flex-col', {
        'bg-none bg-[#999]': !isMileStone,
      })}
      style={{ clipPath: !isMileStone ? 'polygon(6% 0, 94% 0, 100% 50%, 94% 100%, 6% 100%, 0% 50%)' : '' }}
    >
      <div className='text-4xl'>{rewards[0]} Users</div>
      <div className='text-2xl font-bold'>{rewards[1]} $MMF Token</div>
    </DesignButton>
  </Box>
);

const mileStones = [
  {
    className: 'absolute left-[-40px] bottom-[80px]',
    imageSrc: require('../assets/images/MilestonePicture1.png'),
    rewards: ['5,000', '2,500'],
    value: 5000,
  },
  {
    className: 'absolute left-[90px] top-[90px]',
    imageSrc: require('../assets/images/MilestonePicture2.png'),
    rewards: ['20,000', '15,000'],
    value: 20000,
  },
  {
    className: 'absolute left-[310px] top-[228px]',
    imageSrc: require('../assets/images/MilestonePicture3.png'),
    rewards: ['50,000', '50,000'],
    value: 50000,
  },
  {
    className: 'absolute right-[240px] top-[100px]',
    imageSrc: require('../assets/images/MilestonePicture4.png'),
    rewards: ['100,000', '150,000'],
    value: 100000,
  },
  {
    className: 'absolute right-[-40px] bottom-[96px]',
    imageSrc: require('../assets/images/MilestonePicture5.png'),
    rewards: ['200,000', '360,000'],
    value: 200000,
  },
];
const prizes = [
  {
    mileStones: 5000,
    pools: 2500,
    winners: 100,
  },
  {
    mileStones: 20000,
    pools: 15000,
    winners: 500,
  },
  {
    mileStones: 50000,
    pools: 50000,
    winners: 1250,
  },
  {
    mileStones: 100000,
    pools: 150000,
    winners: 3000,
  },
  {
    mileStones: 200000,
    pools: 360000,
    winners: 6000,
  },
];

const Milestone = () => {
  const [openRules, setOpenRules] = useState(false);
  const { data } = useQuery(['marketService.getNumberOfPreRegistrations'], () =>
    marketService.getNumberOfPreRegistrations(),
  );

  const isMileStone = () => {
    for (let i = 0; i < mileStones.length; i++) {
      if (i < mileStones.length - 1) {
        if (data >= mileStones[i].value && data < mileStones[i + 1].value) {
          return i;
        } else if (data >= mileStones[mileStones.length - 1].value) return mileStones.length - 1;
      }
    }
  };

  return (
    <Container className='relative flex flex-col items-center py-10 md:py-20'>
      <div
        data-aos='fade-up-right'
        data-aos-duration='2000'
        className='hidden md:block absolute left-0 top-[50%] w-[160px]'
      >
        <img src={require('../assets/icons/BUTTER2.png')} className='go-jump' />
      </div>

      <img src={require('../assets/icons/DECOR1.png')} className='absolute go-jump right-0 top-40 xl:block hidden' />
      <img
        src={require('../assets/icons/DECOR8.png')}
        className='absolute go-jump right-[-320px] bottom-80 xl:block hidden'
      />

      <DesignBox
        style={{
          background: `url(${require('assets/components/FrameBoxLarge.png')}) no-repeat center / contain`,
        }}
        className='text-xs md:text-[32px] md:leading-[36px] lg:leading-[48px] h-[80px] md:h-[240px] w-full p-0 lg:px-[170px] lg:py-[72px]'
      >
        REACH TO MILESTONE - RECEIVE TREMENDOUS GIFTS
      </DesignBox>
      <div className='font-black text-xl md:text-3xl mt-6'>NUMBER OF PRE-REGISTRATIONS</div>
      <div className='flex justify-center gap-2 mt-6'>
        {String(data)
          .split('')
          .map((num, index) => (
            <div
              key={index}
              className='w-[52px] h-[52px] font-bold text-3xl bg-white text-[#1B2986] rounded-xl flex justify-center items-center'
            >
              {num}
            </div>
          ))}
      </div>
      <div className='font-medium text-base md:text-2xl my-4'>
        Gifts will be sent to the wallets when the tokens are listed
      </div>

      <div className='py-[72px] relative 2xl:block hidden'>
        <img src={require('../assets/images/MilestoneBackground.png')} className='hidden 2xl:block' />

        {mileStones.map((item, index) => (
          <StepBox
            key={index}
            className={item.className}
            imageSrc={item.imageSrc}
            rewards={item.rewards}
            isMileStone={isMileStone() === index}
          />
        ))}
      </div>
      <DesignButton className='scale-[0.6] md:scale-[1]' onClick={() => setOpenRules(true)}>
        SEE RULES
      </DesignButton>

      <DesignDialog maxWidthType='md' open={openRules}>
        <RulesPopup onClose={() => setOpenRules(false)}>
          <div className='text-center font-extrabold mb-6'>Register milestones program – Get more valuable gifts!</div>
          <div className='text-sm'>
            <p>Participant: All Players</p>
            <p>Registration is on!</p>
            <p className='my-6'>
              To unlock the “Registration Rewards”, players only complete one task - Register account on the Battle
              Royale site or in game.
            </p>
            <p>Come and explore Battle Royale with us! Register account now to get the exciting reward!</p>
            <p className='text-[#48DAFF] italic my-6'>
              Milestone Campaign Event for All Registered Players. The total prize pool is up to 360,000 $MMF Token
            </p>
            <p className='uppercase mb-3'>Prize details:</p>
            <TableContainer component={Paper} className='rounded-none bg-transparent'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className='bg-[#B89628] font-extrabold text-xs' align='center'>
                      Milestones For Registered <br /> Player
                    </TableCell>
                    <TableCell className='bg-[#B89628] font-extrabold text-xs' align='center'>
                      $MMF Token Pool
                    </TableCell>
                    <TableCell className='bg-[#B89628] font-extrabold text-xs' align='center'>
                      The number of winners <br />
                      (Random selection)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prizes.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align='center' className='text-xs'>
                        {numberWithCommas(item.mileStones)}
                      </TableCell>
                      <TableCell align='center' className='text-xs'>{`${numberWithCommas(item.pools)} MMF`}</TableCell>
                      <TableCell align='center' className='text-xs'>
                        {numberWithCommas(item.winners)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='flex justify-start mt-6 font-normal'>
              <div className='mr-4'>NOTE</div>
              <div className='italic'>
                - The total prize pool corresponding to each milestone will be shared equally among all lucky winners
                <br />
                - The number of winners will be randomly selected for each milestone
                <br /> - Prizes will be distributed after the token listing event
              </div>
            </div>
          </div>
        </RulesPopup>
      </DesignDialog>
    </Container>
  );
};

export default Milestone;
