import { QueryClientProvider } from '@tanstack/react-query';
import { AppTheme } from 'containers';
import { PublicLayout } from 'layouts';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers/store';
import { queryClient } from 'services';
import AOS from 'aos';
import { gapi } from 'gapi-script';
import { GOOGLE_CLIENT_ID } from 'env';
import { useEffect } from 'react';

AOS.init();

const App = () => {
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  return (
    <ReduxProvider store={store}>
      <SnackbarProvider
        preventDuplicate={false}
        autoHideDuration={3000}
        variant='success'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <QueryClientProvider client={queryClient}>
          <AppTheme>
            <BrowserRouter>
              <Routes>
                <Route path='/*' element={<PublicLayout />} />
              </Routes>
            </BrowserRouter>
          </AppTheme>
        </QueryClientProvider>
      </SnackbarProvider>
    </ReduxProvider>
  );
};

export default App;
