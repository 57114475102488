import { AppController, AppFooter, AppHeader } from 'containers';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { publicRoute } from 'routes';

const PublicLayout = () => {
  const location = useLocation();
  const { isLoggedIn } = useSelector(profileSelector);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <main className='overflow-hidden pt-[100px] 2xl:pt-0 2xl:mt-[-60px]'>
      <AppHeader />
      <AppController>
        <Routes>
          {Object.values(publicRoute)
            .filter(({ requiredLogin }: any) => !requiredLogin || isLoggedIn)
            .map(({ path, component: Element }) => (
              <Route key={path} path={path} element={<Element />} />
            ))}
          <Route path='*' element={<Navigate to={publicRoute.home.path} />} />
        </Routes>
      </AppController>
      <AppFooter />
    </main>
  );
};

export default PublicLayout;
