import { DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import { CloseButton, QrCodeGenerate } from 'components';
import { stringify } from 'qs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { copy, merge } from 'utils/common';

const shorten = (link: string, head = 12, tail = 7) => {
  return link.slice(0, head) + '...' + link.slice(link.length - tail);
};

const ReferralPopup = ({ onClose }: { onClose: () => void }) => {
  const { refCode, totalRefs } = useSelector(profileSelector);

  const [refLink] = useMemo(
    () => [window.location.origin + stringify({ tab: 'account', refCode }, { addQueryPrefix: true })],
    [refCode],
  );

  return (
    <>
      <DialogTitle className='text-center text-white mt-5'>REFERRAL EVENT</DialogTitle>
      <DialogContent>
        <div className='flex flex-col items-center text-white'>
          <Typography variant='h6' className='font-bold my-8'>
            Refer your friends. Get Reward!
          </Typography>
          <div className='flex flex-col items-center sm:flex-row sm:justify-between gap-4'>
            <div className='flex flex-col justify-between items-center sm:items-start gap-4'>
              <div>
                <div className='text-xs text-center sm:text-left'>Your Referral Code</div>
                <div
                  className={merge('flex justify-center sm:justify-start items-center', {
                    invisible: !refCode,
                  })}
                >
                  <div className='font-bold'>{refCode}</div>
                  <IconButton onClick={() => copy(refCode)} title='Copy'>
                    <img src={require('assets/components/Copy.svg').default} className='' />
                  </IconButton>
                </div>
              </div>
              <div>
                <div className='text-xs text-center sm:text-left'>Your Referral Link</div>
                <div
                  className={merge('flex items-center', {
                    invisible: !refCode,
                  })}
                >
                  <div className='font-bold'>{shorten(refLink)}</div>
                  <IconButton onClick={() => copy(refLink)} title='Copy'>
                    <img src={require('assets/components/Copy.svg').default} className='' />
                  </IconButton>
                </div>
              </div>
            </div>
            <div
              className={merge('p-3', {
                'bg-white': refCode,
              })}
            >
              {refCode ? <QrCodeGenerate value={refLink} size={88} /> : <div className='w-[88px] h-[88px]'></div>}
            </div>
          </div>
          <div
            className='mt-8 w-full text-lg text-center py-2 font-bold'
            style={{
              background:
                'linear-gradient(90deg, rgba(100, 130, 255, 0) 0%, #6482FF 55.21%, rgba(100, 130, 255, 0) 100%)',
            }}
          >
            Total Referrals: {totalRefs ?? 0}
          </div>
        </div>
      </DialogContent>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default ReferralPopup;
