import { ListItemButton } from '@mui/material';
import { styled } from '@mui/system';

const StyledListItem = styled(ListItemButton)({
  '@media (min-width: 1280px)': {
    borderRadius: 20,
  },
  fontWeight: 700,
  color: 'white',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#ffaf361f !important',
  },
  '@media (max-width: 1280px)': {
    padding: 16,
  },
});

type NavItemProps = {
  path?: string;
  target?: string;
  name: string;
};

const NavItem = ({ path, target, name }: NavItemProps) => {
  return path ? (
    <a href={path} target='_blank' rel='noreferrer'>
      <StyledListItem>{name}</StyledListItem>
    </a>
  ) : (
    <div className='border-b xl:border-none'>
      <StyledListItem
        onClick={() => {
          document.getElementById(target!)?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {name}
      </StyledListItem>
    </div>
  );
};

const Menu = () => {
  return (
    <>
      <NavItem path='https://www.mymetafarm.com' name='Home' />
      <NavItem target='REFERRAL' name='Referral Program' />
      <NavItem target='GAME' name='Game Rewards' />
      <NavItem target='EXCLUSIVE' name='Exclusive Features' />
      <NavItem path='https://news.mymetafarm.com/battle-royale-the-first-game-hub-at-my-meta-farm' name='About Us' />
    </>
  );
};

export default Menu;
