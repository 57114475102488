import { DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from 'components';

type RulesPopupProps = {
  children?: React.ReactNode;
  onClose: () => void;
};

const RulesPopup = ({ children, onClose }: RulesPopupProps) => {
  return (
    <>
      <DialogTitle className='text-center text-white mt-5'>RULES</DialogTitle>
      <DialogContent>
        <div
          style={{
            background:
              'linear-gradient(90deg, rgba(113, 178, 192, 0) 0%, rgba(93, 164, 204, 0.5) 56.25%, rgba(113, 168, 192, 0) 100%)',
          }}
          className='py-4 px-4 lg:py-6 lg:px-24 text-white font-bold max-h-[320px]'
        >
          {children}
        </div>
      </DialogContent>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default RulesPopup;
