import { Banner, Campaign, Feature, Milestone, Referral, Register } from './components';

const Home = () => {
  return (
    <div className='text-white'>
      <Banner />
      <Milestone />
      <Referral />
      <Campaign />
      <Feature />
      <Register />
    </div>
  );
};

export default Home;
